import { ReactNode, useRef, useState } from 'react'
import { useDeletePlanningMutation, useGetAllRouteQuery, useGetCompanyPlanningQuery } from '../../service/travelMgrApi'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Planning, Route } from '../../service/type'
import { Button } from 'primereact/button'
import { DataView } from 'primereact/dataview'
import { classNames } from 'primereact/utils'
import { Card } from 'primereact/card'
import { ScheduleForm } from './ScheduleForm'
import { FullRouteDisplayTemplate, LightRouteDisplayTemplate, RouteDisplayTemplate } from '../route/RouteDisplayTemplate'
import { OccurenceDisplayTemplate } from './occurence/OccurenceDisplayTemplate'
import { PriceComponent } from './Price'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast'
import { EmptyTableMessage } from '../../component/shared/table/EmptyTableMessage'
import { DepatureTimeTemplate } from './table/DepatureTimeTemplate'
import { CardHeader } from '../../component/shared/CardHeader'
import { BusTag } from '../../component/shared/template/BusTemplate'

export const ScheduleHomePage = () => {

    const toast = useRef<Toast>(null);

    const {data: planningQData, error: planningQError, isLoading: planningQIsLoading, status: planningQStatus, isError: planningQIsError, isFetching: planningQIsFetching} = useGetCompanyPlanningQuery()
    const { data: routesData, error: routeQError, isLoading: routeQLoading, status: routeQStatus, isError: routeQIsError, isFetching: routeQIsFetching } = useGetAllRouteQuery()
    const [deletePlanningApi] = useDeletePlanningMutation()
    const [visible, setVisible] = useState<boolean>(false)
    const [selectedRoute, setSelectedRoute] = useState<Route>()

    const navigate = useNavigate();

    const onplanningDeletion = async (planningId : number) => {
      try {
          await deletePlanningApi(planningId).unwrap()
      } catch (error) {
          throw error
      }
  }
    
  const planningDataViewHeader = () => {
    return <div className="flex gap-3" style={{backgroundColor: "transparent"}}>
        <Dropdown
            id='route'
            loading={routeQLoading || routeQIsFetching}
            showClear
            value={selectedRoute}
            itemTemplate={(route: Route) => <RouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={1} />}
            valueTemplate={(route: Route) => route ? <LightRouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={2} /> : <div>Filtrer par itinéraire</div>}  
            scrollHeight="400px"
            onChange={(e) => setSelectedRoute(e.value)}
            options={routesData}
            optionLabel="name"
            placeholder="Filtrer par itinéraire"
            className="w-30rem" />
    </div>
  }

      const itemTemplate = (planning: Planning, index: number) => {
        return <div className="col-12 bg-white my-5 border-1 border-gray-200 border-round-xl p-0" key={planning.id}>
            <div className={classNames("flex flex-row hover:bg-primary-50 cursor-pointer")}>
                <div className="bg-gray-200 px-1 font-bold align-content-center border-round-left-xl bg-primary"></div>

                <div className='flex flex-grow-1 py-2 overflow-x-auto common-scroll' onClick={(e) => {navigate("/planning/"+planning.id, {state: {planning: planning}})}}>
                  <FullRouteDisplayTemplate route={planning.route} titleFontSize={2} contentFontSize={1} nameTag={planning.time} displayAlertes={false}/>
                  <div style={{color: '#000000'}} className='flex flex-column p-button-warning flex-grow-1 align-content-center justify-content-center align-items-center'>
                    <div className='text-center my-3'>
                      <OccurenceDisplayTemplate occurence={planning.occurrence} />
                    </div>
                    <div>
                      <div className='mb-2'>
                        <DepatureTimeTemplate planning={planning} />
                      </div>
                      <BusTag bus={planning.bus} />
                    </div>
                  </div>

                  <div className='flex flex-grow-1 flex-column align-content-center justify-content-around align-items-center m-2 '>
                    {planning.prices.map(price => <PriceComponent key={price.fareClass.code} price={price} />)}
                  </div>
                </div>
                
                <div className="bg-gray-200 font-bold align-content-center border-round-right-xl">
                  <div className='flex flex-column gap-2'>
                      <Button size='small' icon="pi pi-pencil" text  rounded  aria-label="Filter"  severity='contrast'/>
                      <Button size='small' icon="pi pi-trash" text  rounded aria-label="Filter" severity='contrast' onClick={(e) => {onplanningDeletion(planning.id)}} />
                  </div>
                </div>
            </div>
        </div>   
    }


  const listTemplate = (plannings: Planning[]) : ReactNode[] => {
    return plannings.map((planning, index) => itemTemplate(planning, index))
  }


  return (
    <Card 
      title={<CardHeader title='Gestion des horaires' buttonLabel='Plannifier un voyage' onButtonClick={setVisible}/>} 
      className='shadow-none' 
      style={{backgroundColor: "transparent"}} 
      pt={{
        content: {
          className: "p-0"
        }}}>

        {(planningQIsLoading || planningQIsFetching) &&
            <div className="text-center align-content-center" style={{height: "80vh"}}>
                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
            </div>
        }

        { planningQData && <>
          <div className='mt-5'>
            {planningQData && planningQData.length >0 && planningDataViewHeader()}
          </div>
          <DataView 
            value={[...planningQData].filter(detailedTravel => selectedRoute ? selectedRoute.id === detailedTravel.route.id : true)} 
            listTemplate={listTemplate} 
            paginator 
            alwaysShowPaginator={false}
            rows={4}
            pt={{
              content: {
                  style: {
                      "backgroundColor": "transparent",
                  }
              },
              paginator: {
                  root: {
                      className: "border-round-xl bg-red-500 text-3xl p-5",
                      style: {
                          "backgroundColor": "red"
                      }
                  }
              }
          }}/>
          </>
          }

        {
          planningQData && planningQData.length === 0 &&
          <div className='flex flex-column align-items-center justify-content-center' style={{height: "80vh"}}>
            <EmptyTableMessage message="Aucun programe de voyage" callToActionLabel='Créer un planning' callToAction={setVisible} />
          </div>
        }
        
        <ScheduleForm visible={visible} setVisible={setVisible} toastRef={toast} />
        <Toast ref={toast} position='bottom-right' />
    </Card>
  )
}
