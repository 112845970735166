import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { MessageInfo } from '../../../../service/type';
import { MESSAGE_TYPE_MAP } from '../../../../utils';
import { differenceInMilliseconds } from 'date-fns';

interface MessageListProps {
    messages: MessageInfo[];
}

export const MessageList = ({ messages }: MessageListProps) => {
    const sortedMessageInfo = [...messages].sort((a, b) => 
        differenceInMilliseconds(a.sendDate, b.sendDate)
    );

    const messageTypeTemplate = (messageInfo: MessageInfo) => (
        <span>{MESSAGE_TYPE_MAP.get(messageInfo.messageType)}</span>
    );

    const statusIconTemplate = (value: boolean) => (
        <i className={classNames("pi", {
            "pi-check-circle text-green-500": value,
            "pi-ban text-gray-500": !value
        })}/>
    );

    return (
        <>
            <small>Messagerie</small>
            <DataTable 
                size="small" 
                value={sortedMessageInfo}
                className="common-scroll"
                pt={{
                    column: {
                        headerCell: {
                            className: 'font-normal'
                        }
                    },
                    wrapper: {
                        className: "common-scroll"
                    }
                }}
            >
                <Column 
                    header='Type'
                    body={messageTypeTemplate}
                />
                <Column 
                    header="Envoyé"
                    body={(messageInfo: MessageInfo) => statusIconTemplate(messageInfo.sentToRecipient)}
                />
                <Column 
                    header="Réçu"
                    body={(messageInfo: MessageInfo) => statusIconTemplate(messageInfo.deliveredToRecipient)}
                />
                <Column 
                    header="Lu"
                    body={(messageInfo: MessageInfo) => statusIconTemplate(messageInfo.readByRecipient)}
                />
            </DataTable>
        </>
    );
};
