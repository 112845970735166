import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ReservationItem } from '../../../../service/type';

interface PassengerListProps {
    reservationItems: ReservationItem[];
}

export const PassengerList = ({ reservationItems }: PassengerListProps) => {

    return (
        <div className="mb-4">
            <small>Passagers</small>
            <DataTable 
                size="small" 
                value={reservationItems}
                className="common-scroll"
                pt={{
                    column: {
                        headerCell: {
                            className: 'font-normal'
                        }
                    },
                    headerRow: {
                        className: 'hidden'
                    },
                    wrapper: {
                        className: "common-scroll"
                    },
                }}
            >
                <Column field="passenger.firstName" header="Prénom"/>
                <Column field="passenger.lastName" header="Nom"/>
            </DataTable>
        </div>
    );
};
