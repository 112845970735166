import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useLazyRequestTravelBillQuery } from '../../../../service/bookingApi';

interface BillRequestButtonProps {
    bookingId: string;
}

export const BillRequestButton = ({ bookingId }: BillRequestButtonProps) => {
    const toast = useRef<Toast>(null);
    const [requestTravelBill, { 
        isFetching: billRequestIsFetching, 
        isLoading: billRequestIsLoading 
    }] = useLazyRequestTravelBillQuery();

    const handleBillRequest = async () => {
        try {
            const result = await requestTravelBill({ bookingId });
            toast.current?.show({
                severity: 'success',
                summary: 'Succès',
                detail: result.data,
                life: 5000
            });
        } catch (error) {
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Une erreur est survenue. Réessayer plus tard',
                life: 4000
            });
        }
    };

    return (
        <>
            <div className='mt-2 flex flex-row-reverse'>
                <Button
                    label='Envoyer la facture'
                    size='small'
                    loading={billRequestIsFetching || billRequestIsLoading}
                    onClick={handleBillRequest}
                    pt={{
                        root: {
                            className: "py-1"
                        }
                    }}
                />
            </div>
            <Toast ref={toast} />
        </>
    );
};
