import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MessageInfo} from "./type";


export const messagingApi = createApi({
	reducerPath: "messagingApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://messaging.fasocarexpress.com/api/fce/messaging/",
		timeout: 2000,
		prepareHeaders: (headers, { getState }) => {
			return headers
		}
	}),

	endpoints: (builder) => ({
		getBookingMessagesInfo: builder.query<MessageInfo[], {bookingId: string}>({
			query: (param : {bookingId: string}) => {
				return {
					url: `messageInfos/${param.bookingId}`,
					method: "GET"
				}
			}
		})
	})
})


export const {
	useLazyGetBookingMessagesInfoQuery
} = messagingApi;
