import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PassengerReservation, Reservation } from "./type";


export const bookingApi = createApi({
	reducerPath: "bookingApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://booking.fasocarexpress.com/api/fce/booking/",
		//baseUrl: "http://localhost/api/fce/booking/",
		timeout: 2000,
		prepareHeaders: (headers, { getState }) => {
			return headers
		}
	}),

	endpoints: (builder) => ({
		getTravelPassengers: builder.query<PassengerReservation[], {travelId: number}>({
			query: (param : {travelId: number}) => {
				return {
					url: `booking/travel/${param.travelId}`,
					method: "GET"
				}
			},
			keepUnusedDataFor: 0
		}),

		getReservation: builder.query<Reservation, {bookingId: string}>({
			query: (param : {bookingId: string}) => {
				return {
					url: `booking/${param.bookingId}`,
					method: "GET"
				}
			}
		}),

		requestTravelBill: builder.query<string, {bookingId: string}>({
			query: (param : {bookingId: string}) => {
				return {
					url: `booking/bill/${param.bookingId}`,
					method: "GET",
					responseHandler: 'text'
				}
			}
		})
	})
})


export const {
	useLazyGetTravelPassengersQuery,
	useGetTravelPassengersQuery,
	useLazyGetReservationQuery,
	useLazyRequestTravelBillQuery
} = bookingApi;
