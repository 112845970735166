import { Button } from "primereact/button"
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";



export const CardHeader = ({title, buttonLabel, onButtonClick, filterValue, onFilter, buttonClassName}: {title: string, buttonLabel?: string, onButtonClick?: Function, filterValue?: string, onFilter?: Function, buttonClassName?: string | undefined;}) => {

  return <div className='flex justify-content-between flex-wrap'>
      <div>{title}</div>
      <div className='flex align-items-center justify-content-center gap-4'>

        { onFilter &&
          <div className="flex flex-row-reverse">
          <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                  placeholder="Search" type="text" 
                  className="p-inputtext-sm w-8rem sm:w-auto" 
                  value={filterValue} onChange={(e) => onFilter(e.target.value)} 
                  pt={{
                      root: {
                        style: {
                          "borderRadius": "2rem",
                          "paddingTop": ".5rem",
                          "paddingBottom": ".5rem"
                        }
                      }
                    }}/>
          </IconField>
      </div>
        }
      { (onButtonClick || buttonLabel) &&
        <Button size='small' type="button" outlined
          className={'mt-1 '+buttonClassName} icon="pi pi-plus"
          label={buttonLabel} onClick={() => {if(onButtonClick) onButtonClick(true)}} pt={{
          root: {
            className: "py-2"
          }
        }}
        />
      }
    </div>
  </div>
}


