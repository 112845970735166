import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";
import { SimpleRouteDisplayTemplate } from "../../route/RouteDisplayTemplate";
import { formatDateString, formatTimeString } from "../../../DateUtils";
import { Travel } from "../../../service/type";

export interface TravelItemProps {
    travel: Travel;
    isSelected: boolean;
    onSelect: (travel: Travel) => void;
}

export const TravelItem = ({ travel, isSelected, onSelect }: TravelItemProps) => {
    const startDateBody = (travel: Travel) => (
        <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis text-primary text-lg font-bold">
            {formatDateString(travel.startDate)}
        </div>
    );

    const startTimeBody = (travel: Travel) => (
        <div className="block text-center">
            {formatTimeString(travel.startTime)} - {formatTimeString(travel.endTime)}
        </div>
    );

    return (
        <div 
            className={classNames("hover:bg-primary-50", {"bg-primary-50": isSelected})} 
            onClick={() => onSelect(travel)}
        >
            <div className="flex cursor-pointer border-round-xl py-3">
                <div className="flex-grow-1 overflow-hidden text-overflow-ellipsis">
                    <SimpleRouteDisplayTemplate 
                        route={travel.route} 
                        titleFontSize={2} 
                        contentFontSize={1}
                    />
                </div>
                <div className="mr-3 flex flex-column justify-content-center">
                    {startDateBody(travel)}
                    {startTimeBody(travel)}
                </div>
            </div>
            <Divider pt={{ root: { className: "m-0" } }}/>
        </div>
    );
};
