import { useClosePlanningTravelToMarketMutation, useLazyGetTravelsInPlanningQuery, useOpenPlanningTravelToMarketMutation } from "../../service/travelMgrApi";
import { Planning, Travel } from "../../service/type";
import { formatDateString, formatTimeString } from "../../DateUtils";
import { Badge } from "primereact/badge";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FullRouteDisplayTemplate } from "../route/RouteDisplayTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChild, faPersonWalkingLuggage, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { ProgressSpinner } from "primereact/progressspinner";
import { classNames } from "primereact/utils";
import { DataView } from "primereact/dataview";
import { Divider } from "primereact/divider";
import { BusTag } from "../../component/shared/template/BusTemplate";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { SelectItem } from "primereact/selectitem";

enum ActionType{
    OPEN,
    CLOSE
}

export const ScheduleDetails = () => {
    
    const {planningId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const planning = (location.state.planning as Planning)
    const [ getTravelsInPlanning, {data: travelQData, error: travelQError, isLoading: travelQLoading, status: travelQStatus, isError: travelQIsError, isFetching: travelQIsFetching }] = useLazyGetTravelsInPlanningQuery(/*{planningId: planningId}*/)
    const [selectedTravel, setSelectedTravel] = useState<Travel>()
    const [openPlanningTravelToMarket] = useOpenPlanningTravelToMarketMutation()
    const [closePlanningTravelToMarket] = useClosePlanningTravelToMarketMutation()

    useEffect(() => {
        getTravelsInPlanning({planningId: planningId})
    }, [])

    useEffect(() => {
        setSelectedTravel(travelQData && travelQData.length > 0 ? [...travelQData].sort((a, b) => (a.startDate+a.startTime).localeCompare(b.startDate+b.startTime))[0]  : undefined)
    }, [travelQData])


    const dataToDisplay = useCallback(() => {
        return (travelQData ? [...travelQData].sort((a, b) => (a.startDate+a.startTime).localeCompare(b.startDate+b.startTime)) : [])
    }, [travelQData])

    const startDateBody = (rowData: Travel) => {
        return <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis text-primary text-lg font-bold mb-1">{formatDateString(rowData.startDate)}</div>
           
    }

    const travelsOpenedToSellSize = travelQData?.filter(travel => travel.sellAllowed).length
    const travelsClosedToSellSize = travelQData?.filter(travel => !travel.sellAllowed).length

    const options: SelectItem[] = [
        {label : 'Tout ouvrir à la vente', value : ActionType.OPEN,  disabled : travelsOpenedToSellSize !== undefined && travelsOpenedToSellSize === travelQData?.length},
        {label: 'Tout fermer à la vente', value : ActionType.CLOSE, disabled: travelsClosedToSellSize !== undefined && travelsClosedToSellSize === travelQData?.length}
    ];

    const salesManagement = (travel: Travel) => {
        return <div className="align-content-center">
            { 
                <Button
                size='small' type="button"
                label={travel.sellAllowed ? 'Suspendre la vente' : 'Ouvrir à la vente'}
                outlined
                severity={travel.sellAllowed ? "warning" : "info"}
                pt={{
                root: {
                    style: {
                    "paddingTop": ".5rem",
                    "paddingBottom": ".5rem"
                    }
                }
                }}/>
            }
        </div>           
    }
    
    const startTimeBody = (rowData: Travel) => {
        return <div className="block">
            <div className="text-center">{formatTimeString(rowData.startTime)}  {formatTimeString(rowData.endTime)}</div>
            <Badge value={rowData.duration} severity="info" size="normal" className="flex justify-content-center"/>
            </div>
    }

    const itemTemplate = (travel: Travel, index: number) => {
        return <div className={classNames("hover:bg-primary-50", {"bg-primary-50": travel.id === selectedTravel?.id})} onClick={() => setSelectedTravel(travel)} >
            <div className="flex cursor-pointer border-round-xl py-3" >
                <div className="mx-3 flex gap-5 flex-grow-1">
                    <div>
                        <div>{startDateBody(travel)}</div>
                        <div>{startTimeBody(travel)}</div>
                    </div>
                    <div className="flex-grow-1 justify-content-center flex">
                        {salesManagement(travel)}
                    </div>
                </div>
            </div>
            <Divider pt={{
                    root: {
                        className: "m-0"
                    }
                }}/>
        </div>
    }

    const listTemplate = (travels: Travel[]) : ReactNode[] => {
        if (!travels || travels.length === 0) return [<div></div>];

        let list = travels.map((detailedTravel, index) => {
            return itemTemplate(detailedTravel, index)
        });

        return list;
    }

    const tableHeader = <div className="flex flex-wrap flex-row pb-3">
        <div 
              className='flex hover:bg-primary cursor-pointer align-content-center align-items-center justify-content-center border-circle mr-1' 
              style={{width : "2.25rem", height: "2.25rem"}}
              onClick={(e)=> navigate(-1)}
              >
            <FontAwesomeIcon icon={faChevronLeft} className="font-bold text-2xl" />
        </div>
        <div className="overflow-hidden mb-2 flex-grow-1">
            <div className="text-primary font-bold text-2xl white-space-nowrap overflow-hidden text-overflow-ellipsis flex">
                {planning.route.name}
                <div className="align-items-center flex">
                    <Tag 
                        severity="danger" 
                        value={planning.time}
                        className="ml-2"
                        pt={{
                        root: {
                            className: "border-noround"
                        }
                        }
                    }/>
                </div>
                </div>
            <small>du {formatDateString(planning.startDate)} au {formatDateString(planning.endDate)}</small>
        </div>
        <div className="overflow-x-auto common-scroll">
            <FullRouteDisplayTemplate route={planning.route} contentFontSize={1} titleFontSize={2} hideTitle={true}/>
        </div>
    </div>

    const loader = <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
        </div>

    return (
        <div className="m-0 card zoomin animation-duration-500">
            {tableHeader}
            <div className="grid">
                <div className="col-12 xl:col-5">
                    <div className="card zoomin animation-duration-500 mx-2 p-3 h-full">

                        <div className="flex flex-row-reverse mb-2">
                            {
                                <div className="flex align-items-center">
                                <SelectButton 
                                    key="travelsSellStatusToggle" 
                                    //value={value} 
                                    onChange={(event: SelectButtonChangeEvent) => {
                                        switch(event.value as ActionType) {
                                            case ActionType.CLOSE:
                                                closePlanningTravelToMarket({planningId: planning.id})
                                                .then(result => getTravelsInPlanning({planningId: planningId}))
                                                break
                                            case ActionType.OPEN:
                                                openPlanningTravelToMarket({planningId: planning.id})
                                                .then(result => getTravelsInPlanning({planningId: planningId}))
                                                break
                                        }
                                    }} 
                                    options={options} 
                                    pt={{
                                        button: {
                                            className: "py-1 px-2"
                                        },
                                        label: {
                                            className: "font-medium"
                                        }
                                    }}
                                />
                            </div>
                            }
                        </div>
                        
                        <DataView 
                            value={dataToDisplay()} 
                            listTemplate={listTemplate}
                            loading={travelQLoading || travelQIsFetching} loadingIcon={loader}
                            //header= {travelDataViewHeader()}
                            paginator 
                            rows={3} />
                    </div>
                </div> 
                {
                    selectedTravel && <div className="col-12 xl:col-7 fadeinright animation-duration-300">
                    <div className="flex flex-column gap-3 h-full">
                        <div className="card w-full mb-0 p-4">
                            <div className="flex flex-row gap-3 flex-wrap w-full mb-5">
                                <Badge 
                                    value={
                                        <span className="flex gap-2 align-items-center">
                                            <span className="white-space-nowrap">{selectedTravel?.sellAllowed? "VENTE OUVERTE" : "VENTE FERMÉE"}</span>
                                        </span>} 
                                    severity={!selectedTravel?.sellAllowed ? 'danger': 'success'} 
                                    size="normal" 
                                    className="flex justify-content-center py-3 cursor-pointer flex-grow-1"
                                    pt={{
                                        root: {
                                            className: "border-noround"
                                        }
                                    }}
                                />
                                    
                                <Badge 
                                    value={
                                        <span className="flex gap-2 align-items-center">
                                            <span className="white-space-nowrap">{selectedTravel?.ticketAvailable? 'BILLET DISPONIBLE' : "BUS COMPLET"}</span>
                                        </span>} 
                                    severity={!selectedTravel?.ticketAvailable ? 'danger': 'success'} 
                                    size="normal" 
                                    className="flex justify-content-center py-3 cursor-pointer flex-grow-1"
                                    pt={{
                                        root: {
                                            className: "border-noround"
                                        }
                                    }}
                                />
                            </div>
                            <div className="flex gap-3 flex-wrap">
                                {selectedTravel.prices.map(price => 
                                    <div className="flex flex-grow-1 justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer white-space-nowrap">
                                            <div className="mr-2"><FontAwesomeIcon icon={price.fareClass.code === "CHILD" ? faChild : faPersonWalkingLuggage} className='' size='lg' /></div>
                                            <div className="text-lg mr-2">{price.fareClass.code === "CHILD" ? "Enfant" : "Adulte"}</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right"><span>{price.amount}</span> <small>fcfa</small></div>
                                        </div>
                                    </div>
                                )}
                                </div> 
                        </div>
                        <div className="card w-full h-full mb-0 flex-grow-1 p-4">
                            <div className="flex flex-column">
                                <span className="text-lg font-bold text-primary pb-5"><BusTag bus={selectedTravel.bus} /></span>
                            </div>
    
                            <div className="flex flex-row gap-4 flex-wrap">
                                <div className="flex flex-column flex-grow-1 gap-2">
                                    <div className="font-light font-normal text-xl pb-2">Chauffeurs</div>
                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">1</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Inoussa ZONGO</div>
                                        </div>
                                    </div>

                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">2</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Arouna SANKARA</div>
                                        </div>
                                    </div>
                                </div>
    
                                <div className="flex flex-column flex-grow-1 gap-2">
                                    <div className="font-light font-normal text-xl pb-2">Hôtesses</div>
                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">1</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Adeline NASSÉ</div>
                                        </div>
                                    </div>

                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">2</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Roseline TAMBOURA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>

    )

}