import { Divider } from 'primereact/divider';
import { Reservation } from '../../../../service/type';

interface PaymentInfoProps {
    reservation: Reservation;
}

export const PaymentInfo = ({ reservation }: PaymentInfoProps) => {
    return (
        <div>
            <small>Paiement</small>
            <div>
                <div className="mt-1">Coût total : {reservation.totalCost.toLocaleString()} fcfa</div>
                <div><small>Payment en ligne</small></div>
                <div><small>Orange money</small></div>
            </div>
        </div>
    );
};
