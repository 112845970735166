// External Dependencies
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

// PrimeReact Styles
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"

// Auth Components
import { RequireAuth } from './app/component/auth/AuthProvider';
import { LoginPage } from './app/component/auth/LoginPage';
import { HomePage } from './app/page/HomePage';

// Main Feature Pages
import { DashboardHomePage } from './app/page/dashboard/DashboardHomePage';
import { TravelHomePage } from './app/page/travel/TravelHomePage';
import { ScheduleHomePage } from './app/page/schedule/ScheduleHomePage';
import { ScheduleDetails } from './app/page/schedule/ScheduleDetails';
import { RouteHomePage } from './app/page/route/RouteHomePage';
import StationHomePage from './app/page/station/StationHomePage';
import { FleetHomePage } from './app/page/fleet/FleetHomePage';

// Business & Customer Management
import { FinanceHomePage } from './app/page/finance/FinanceHomePage';
import { BillHomePage } from './app/page/bill/BillHomePage';
import { CustomerHomePage } from './app/page/customer/CustomerHomePage';
import { TicketHomePage } from './app/page/ticket/TicketHomePage';

// Analytics & Settings
import { SurveyHomePage } from './app/page/survey/SurveyHomePage';
import { StatisticsHomePage } from './app/page/statistics/StatisticsHomePage';
import { SettingsHomePage } from './app/page/settings/SettingsHomePage';
import { UsersHomePage } from './app/page/users/UsersHomePage';
import { AuditHomePage } from './app/page/audit/AuditHomePage';

function App() {

  return (
    <PrimeReactProvider>
      <div >
        <Routes>
          {/* Public Routes */}
          <Route path='/login' element={<LoginPage />} />
          
          {/* Protected Routes */}
          <Route path='/' element={<RequireAuth><HomePage /></RequireAuth>}>
            {/* Default Route */}
            <Route path='' element={<Navigate to="dashboard" />} />
            
            {/* Main Features */}
            <Route path='dashboard' element={<DashboardHomePage />} />
            <Route path='planning' element={<ScheduleHomePage />} />
            <Route path='planning/:planningId' element={<ScheduleDetails />} />
            <Route path='travel' element={<TravelHomePage />}/>
            <Route path='station' element={<StationHomePage />} />
            <Route path='route' element={<RouteHomePage />} />
            <Route path='fleet' element={<FleetHomePage />} />
            
            {/* Business Operations */}
            <Route path='ticket' element={<TicketHomePage />} />
            <Route path='finance' element={<FinanceHomePage />} />
            <Route path='bill' element={<BillHomePage />} />
            <Route path='customer' element={<CustomerHomePage />} />
            
            {/* Analytics & Administration */}
            <Route path='survey' element={<SurveyHomePage />} />
            <Route path='statistics' element={<StatisticsHomePage />} />
            <Route path='settings' element={<SettingsHomePage />} />
            <Route path='users' element={<UsersHomePage />} />
            <Route path='audit' element={<AuditHomePage />} />
          </Route>
        </Routes>
      </div>
    </PrimeReactProvider>
  )
}

export default App;
