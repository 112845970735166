import { Status } from "./model/common"


export const removeDuplicate = (data: string[]) : string[] => {
    return Array.from(new Set(data))
}

export const getBusStatusSeverity = (status: Status) : 'success' | 'info' | 'warning' | 'danger' | null | undefined => {
    var severity : 'success' | 'info' | 'warning' | 'danger' | null | undefined = undefined
    switch(status.code) {
        case "IN_SERVICE" :
            severity = "success"
            break
        case "MAINTENANCE" :
            severity = "warning"
            break
        case "PENDING":
            severity = "info"
            break
        case "OUT_OF_SERVICE" :
            severity = "danger"
            break
        default :
            severity = "info"
    }
    return severity
}

export const ID_DOC_MAP = new Map<string, string>([
    ["ID_CARD", "Carte d'idéntité"],
    ["PASSEPORT", "Passeport"]
])

export const MESSAGE_TYPE_MAP = new Map<string, string>([
    ["BOOKING_CONFIRMATION", "Confirmation"],
    ["PDF_TICKET_SEND", "Billet PDF"],
    ["QR_CODE_TICKET_SEND", "Billet QR CODE"],
    ["SURVEY_SEND", "Enquêtte"],
    ["BOOKING_BILL_SEND", "Facture"],
    ["TRAVEL_REMINDER", "Rappel"],
])