import { MessageInfo, Reservation } from '../../../../service/type';
import { BookerInfo } from './BookerInfo';
import { PaymentInfo } from './PaymentInfo';
import { PassengerList } from './PassengerList';
import { MessageList } from './MessageList';
import { Divider } from 'primereact/divider';
import { ReservationHeader } from './ReservationHeader';
import { BillRequestButton } from './BillRequestButton';

export interface ReservationDetailsSidebarContentProps {
    reservation: Reservation;
    messagesInfo: MessageInfo[];
}

export const ReservationDetailsSidebarContent = ({ 
    reservation, 
    messagesInfo 
}: ReservationDetailsSidebarContentProps) => {
    const renderSection = (Component: React.ComponentType<any>, props: any, divider = true) => (
        <>
            <Component {...props} />
            {divider && <Divider />}
        </>
    );

    return (
        <div className="flex flex-column">
            {renderSection(ReservationHeader, { reservationId: reservation.id })}
            {renderSection(BookerInfo, { 
                booker: reservation.booker,
                reservationDate: reservation.reservationDate
            })}
            {renderSection(PaymentInfo, { reservation })}
            {renderSection(PassengerList, { 
                reservationItems: reservation.reservationItems 
            }, false)}
            {renderSection(MessageList, { messages: messagesInfo }, false)}
            <BillRequestButton bookingId={reservation.id} />
        </div>
    );
};
