import { Route } from '../../service/type'
import { StationDisplayTemplate } from '../station/StationDisplayTemplate'
import { classNames } from 'primereact/utils'
import { Tag } from 'primereact/tag'
import { Tooltip } from 'primereact/tooltip'
import { Avatar } from 'primereact/avatar'

const planningWarnings = ["Aucune hôtesse n'est affectée à ce planning."]
const planningErrors = ["Aucun bus n’est assigné à ce planning.", "Aucun chauffeur n’est assigné à ce planning."]


export interface RouteDisplayTemplateProps {
    route: Route,
    titleFontSize: 1 | 2,
    nameTag?: string,
    contentFontSize: 1| 2 | 3,
    hideTitle?: boolean,
    displayDuration?: boolean,
    displayAlertes?: boolean
}

const tag = (value: string) => {
    return <Tag 
        severity="danger" 
        value={value}
        className='mx-2'
        pt={{
        root: {
            className: "border-noround"
        }
    }}/>
}


export const RouteDisplayTemplate = ({route, nameTag, titleFontSize, contentFontSize, hideTitle}: RouteDisplayTemplateProps) => {
  return (
    <div className="mx-3 my-1 flex-grow-1 flex-wrap overflow-x-auto common-scroll">
        <div>
            <div className={classNames('font-bold text-900 text-primary white-space-nowrap overflow-hidden text-overflow-ellipsis', {"hidden": hideTitle}, {'text-lg' : titleFontSize===1}, {'text-xl' : titleFontSize===2} )}>{route.name} {nameTag && tag(nameTag)}</div>
            <div className={classNames("flex flex-row gap-3 py-2 overflow-x-auto common-scroll", {'text-sm' : contentFontSize===1}, {'text-lg' : contentFontSize===3}, {'text-xl' : contentFontSize===3})} style={{"color": "#000000"}}>
                <StationDisplayTemplate station={route.fromStation} />
                <div className='align-content-center'>
                <i className="pi pi-angle-double-right" />
                </div>
                {
                <div className='align-content-center'>
                    {
                    route.stops.map(pl =><div>{pl.stop.city.shortName}</div>)
                    }
                </div>
                }
                {
                route.stops.length >0 && 
                <div className='align-content-center'>
                    <i className="pi pi-angle-double-right" />
                </div>
                }
                <StationDisplayTemplate station={route.toStation} />
            </div>
        </div>
    </div>
  )
}

export const FullRouteDisplayTemplate = ({route, nameTag, titleFontSize, contentFontSize, hideTitle, displayDuration, displayAlertes}: RouteDisplayTemplateProps) => {
    return (
      <div className="mx-3 my-1 flex-grow-1 flex-wrap overflow-x-auto common-scroll" >
          <div>
            <Tooltip target={"#idWarning"+route.id}>
                <div data-pr-tooltip="No notifications">
                    {
                        planningWarnings.map(item => <div id={item}>
                            <i 
                                id={"idWarning"+route.id}
                                className="pi pi-exclamation-triangle mr-3"
                                style={{ color: 'orange'}}/>
                            {item}</div>)
                    }
                </div>
            </Tooltip>


            <Tooltip target={"#idError"+route.id}>
                <div data-pr-tooltip="No notifications">
                {
                    planningErrors.map(item => <div><i 
                        id={"idWarning"+route.id}
                        className="pi pi-exclamation-triangle mr-3"
                        style={{ color: 'red'}}/>
                        {item}</div>)
                }
                </div>
            </Tooltip>
            {
                !hideTitle && <div 
                    className={classNames('flex font-bold text-900 text-primary my-3', {'text-lg' : titleFontSize===1}, {'text-xl' : titleFontSize===2} )}>
                        {route.name} {nameTag && tag(nameTag)} 
                        {displayAlertes && <span>
                            <i 
                                id={"idWarning"+route.id}
                                className="pi pi-exclamation-triangle ml-3"
                                style={{ color: 'orange', fontSize: "1.3rem"}}/>
                            <i 
                                id={"idError"+route.id}
                                className="pi pi-exclamation-triangle ml-3 " 
                                style={{ color: 'red', fontSize: "1.3rem" }}/>
                        </span>
                        }
                        { displayAlertes &&
                            <div className='flex-grow-1 flex flex-row-reverse'>
                                <div className='flex gap-2' >
                                    <div>
                                        <Avatar icon="pi pi-car" className='bg-gray-400 text-white' size="normal" shape="circle" />
                                    </div>
                                    <div className='flex'>
                                        <Avatar label="ZI" className='bg-gray-400 text-white' size="normal" shape="circle" />
                                        <Avatar  className='bg-red-500 text-white' size="normal" shape="circle" />
                                    </div>
                                    <div>
                                        <Avatar label="SZ" className='bg-gray-400 text-white' size="normal" shape="circle" />
                                        <Avatar label="SA" className='bg-gray-100 text-primary' size="normal" shape="circle" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
              <div className={classNames("flex flex-row gap-3 overflow-x-auto common-scroll", {'text-sm' : contentFontSize===1}, {'text-lg' : contentFontSize===3}, {'text-xl' : contentFontSize===3})} style={{"color": "#000000"}}>
                  <StationDisplayTemplate station={route.fromStation} />
                  <div className='align-content-center'>
                    <i className="pi pi-angle-double-right" />
                  </div>
                      {
                      route.stops.map(stop => {
                        return <div className='flex gap-3' key={stop.stop.id}>
                                {
                                    displayDuration && 
                                    <>
                                        <div className='align-content-center white-space-nowrap'><i className="pi pi-stopwatch"></i> 1h30</div>
                                        <div className='align-content-center'>
                                            <i className="pi pi-angle-double-right" />
                                        </div>
                                    </>
                                }

                                <StationDisplayTemplate station={stop.stop} />
                                <div className='align-content-center'>
                                    <i className="pi pi-angle-double-right" />
                                </div>
                            </div>
                      })
                  }
                  
                  { displayDuration &&
                    <>
                        <div className='align-content-center white-space-nowrap'><i className="pi pi-stopwatch"></i> 1h30</div>
                        <div className='align-content-center'>
                            <i className="pi pi-angle-double-right" />
                        </div>
                    </>
                  }
                  <StationDisplayTemplate station={route.toStation} />
              </div>
          </div>
      </div>
    )
  }


export const SimpleRouteDisplayTemplate = ({route, nameTag, titleFontSize, contentFontSize}: RouteDisplayTemplateProps) => {
    return (
      <div className="mx-3 my-1 flex-grow-1">
          <div>
              <div className={classNames('font-bold text-900 white-space-nowrap overflow-hidden text-overflow-ellipsis', {'text-lg' : titleFontSize===1}, {'text-xl' : titleFontSize===2} )}>{route.name} {nameTag && tag(nameTag)}</div>
              <div className={classNames("flex flex-row gap-3", {'text-sm' : contentFontSize===1}, {'text-lg' : contentFontSize===3}, {'text-xl' : contentFontSize===3})}>
                  <StationDisplayTemplate station={route.fromStation} />
                  <div className='align-content-center'>
                    <i className="pi pi-angle-double-right" />
                  </div>
                  <StationDisplayTemplate station={route.toStation} />
              </div>
          </div>
      </div>
    )
  }

  export const LightRouteDisplayTemplate = ({route, titleFontSize}: RouteDisplayTemplateProps) => {
    return (
        <div>
            <div className='flex gap-2 align-items-center'>
                <div>
                    <i className={classNames("pi pi-directions font-bold mr-1", {'text-lg' : titleFontSize===1}, {'text-xl' : titleFontSize===2})}></i>
                </div>
                <div>
                    <div className={classNames("font-bold")}>{route.name}</div>
                    <small className='flex justify-content-start my-1'>
                        {route.fromStation.city.longName} 
                        <i className="pi pi-angle-double-right mx-2" />
                        {route.toStation.city.longName}
                    </small>
                </div>
            </div>
        </div>
    )
  }




