import React from 'react'
import { Station } from '../../service/type'

import bf_flag from '../../../assets/images/flag_bf.png'

export interface StationDisplayTemplateProps {
    station: Station,
    showCountryFlag?: boolean
}

export const StationDisplayTemplate = ({station, showCountryFlag} : StationDisplayTemplateProps) => {
  return (
    <div>
        <div className='flex gap-2 align-items-center'>
            { showCountryFlag && 
                <div>
                    <img alt={station.city.country} src={bf_flag} width="32" className='border-circle' />
                </div>
            }
            <div className=''>
                <div className='font-bold white-space-nowrap'>{station.name}</div>
                <small className='block'>{station.city.longName}</small>
                <small className='block'>{station.city.country}</small>
            </div>
        </div>
    </div>
  )
}

