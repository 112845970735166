import { Booker } from '../../../../service/type';
import { formatIsoDateTime } from '../../../../DateUtils';

interface BookerInfoProps {
    booker: Booker;
    reservationDate?: Date;
}

export const BookerInfo = ({ booker, reservationDate }: BookerInfoProps) => (
    <div>
        <small>Effectuée par</small>
        <div className="mt-1">
            <i className="pi pi-user mr-3"/>
            {booker.firstName} {booker.lastName}
        </div>
        <div>
            <i className="pi pi-phone mr-3"/>
            {booker.phoneNumber}
        </div>
        <small>
            <i className="pi pi-calendar-plus mr-3"/>
            {reservationDate ? formatIsoDateTime(reservationDate) : ''}
        </small>
    </div>
);
