import { Chart } from "primereact/chart"
import { useEffect, useState } from "react"

export const DashboardHomePage = () => {

    return <div className="grid">
        <KpiCard label="Chiffre d'affaire" value="12.2 M" delta={12}/>
        <KpiCard label="Reservation" value="1 250" delta={14}/>
        <KpiCard label="Taux d'occupation" value="82.5" unit="%" delta={-5}/>
        <KpiCard label="Voyages" value="100" delta={5.2}/>

        <div className="col-12 xl:col-6">
            <LineChart />
        </div>
        <div className="col-12 xl:col-6">
            <LineChart2 />
        </div>

        <div className="col-12 xl:col-6">
            <PolarAreaDemo />
        </div>
        <div className="col-12 xl:col-6">
            <DoughnutChartDemo />
        </div>

        <div className="col-12">
            <ComboDemo />
        </div>

        
    </div>

}

export interface KpiCardProps {
    label: string,
    value: string,
    unit?: string,
    delta: number
}

export const KpiCard = ({label, value, unit, delta}: KpiCardProps) => {
    return <div className="col-12 md:col-6 xl:col-3">
    <div className="card p-3 lg:p-4">
        <span className="font-semibold text-lg">{label}</span>
        <div className="flex justify-content-between align-items-start mt-3">
            <div className="w-6">
                <div className="text-4xl font-bold text-900">{value} {unit && <small>%</small>}</div>
                <div className="text-green-500">
                    <span className="font-medium">{delta >0 ? "+" : ""}{delta} </span>
                    <i className="pi pi-arrow-up text-xs ml-2"></i>
                </div>
            </div>
            <div className="w-6">
            <svg width="100%" viewBox="0 0 258 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 93.9506L4.5641 94.3162C8.12821 94.6817 15.2564 95.4128 22.3846 89.6451C29.5128 83.8774 36.641 71.6109 43.7692 64.4063C50.8974 57.2018 58.0256 55.0592 65.1538 58.9268C72.2821 62.7945 79.4103 72.6725 86.5385 73.5441C93.6667 74.4157 100.795 66.2809 107.923 65.9287C115.051 65.5765 122.179 73.0068 129.308 66.8232C136.436 60.6396 143.564 40.8422 150.692 27.9257C157.821 15.0093 164.949 8.97393 172.077 6.43766C179.205 3.9014 186.333 4.86425 193.462 12.0629C200.59 19.2616 207.718 32.696 214.846 31.0487C221.974 29.4014 229.103 12.6723 236.231 5.64525C243.359 -1.38178 250.487 1.29325 254.051 2.63076L257.615 3.96827" stroke="10" style={{strokeWidth: "2px", stroke: "var(--primary-color)"}}></path>
            </svg>
            </div>
        </div>
    </div>
</div>
}

export const LineChart = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'En ligne',
                    data: [5, 15, 10, 35, 43, 65, 82],
                    fill: true,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0.4
                },
                {
                    label: 'Au guichet',
                    data: [95, 85, 90, 65, 57, 35, 12],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    tension: 0.4
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="card">
            <div className="font-light font-semibold text-xl pb-5">Reservation en ligne vs reservation au guichet</div>
            <Chart type="line" data={chartData} options={chartOptions} pt={{
                root: {
                    style: {
                        height: '400px'
                    }
                }
            }}/>
        </div>
    )

}



export const DoughnutChartDemo = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ["Chiffre d'affaire", "Dépenses", "Benefices"],
            datasets: [
                {
                    data: [300, 50, 100],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'), 
                        documentStyle.getPropertyValue('--yellow-500'), 
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'), 
                        documentStyle.getPropertyValue('--yellow-400'), 
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%',
            plugins: {
                legend: {
                  display: true,
                  position: 'bottom'
                },
                /*
                title: {
                    display: true,
                    text: 'Custom Chart Title'
                }*/
              }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="card">
            <div className="font-light font-semibold text-xl pb-5">Reservation en ligne vs reservation au guichet</div>
            <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" pt={{
                root: {
                    style: {
                        height: '400px'
                    }
                }
            }} />
        </div>
    )
}


const LineChart2 = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    tension: 0.4,
                    borderColor: documentStyle.getPropertyValue('--blue-500')
                },
                {
                    label: 'Second Dataset',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderDash: [5, 5],
                    tension: 0.4,
                    borderColor: documentStyle.getPropertyValue('--teal-500')
                },
                {
                    label: 'Third Dataset',
                    data: [12, 51, 62, 33, 21, 62, 45],
                    fill: true,
                    borderColor: documentStyle.getPropertyValue('--orange-500'),
                    tension: 0.4,
                    backgroundColor: 'rgba(255,167,38,0.2)'
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);
    
    return (
        <div className="card">
            <div className="font-light font-semibold text-xl pb-5">Reservation en ligne vs reservation au guichet</div>
            <Chart type="line" data={chartData} options={chartOptions} pt={{
                root: {
                    style: {
                        height: '400px'
                    }
                }
            }}/>
        </div>
    )
}


export const PolarAreaDemo = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            datasets: [
                {
                    data: [11, 16, 7, 3, 14],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--red-500'),
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--bluegray-500'),
                        documentStyle.getPropertyValue('--blue-500')
                    ],
                    label: 'My dataset'
                }
            ],
            labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue']
        };
        const options = {
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="card">
            <div className="font-light font-semibold text-xl pb-5">Reservation en ligne vs reservation au guichet</div>
            <Chart type="polarArea" data={chartData} options={chartOptions} className="w-full md:w-30rem" pt={{
                root: {
                    style: {
                        height: '400px'
                    }
                }
            }} />
        </div>
    )
}


export const ComboDemo = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    type: 'line',
                    label: 'Dataset 1',
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: [50, 25, 12, 48, 56, 76, 42]
                },
                {
                    type: 'bar',
                    label: '07:00',
                    backgroundColor: documentStyle.getPropertyValue('--green-500'),
                    data: [21, 84, 24, 75, 37, 65, 34],
                    borderColor: 'white',
                    borderWidth: 2
                },
                {
                    type: 'bar',
                    label: '09:00',
                    backgroundColor: documentStyle.getPropertyValue('--orange-500'),
                    data: [41, 52, 24, 74, 23, 21, 32]
                },
                {
                    type: 'bar',
                    label: '12:00',
                    backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
                    data: [51, 33, 42, 35, 48, 52, 55]
                },
                {
                    type: 'bar',
                    label: '14:30',
                    backgroundColor: documentStyle.getPropertyValue('--red-500'),
                    data: [65, 70, 63, 64, 65, 59, 64]
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="card">
            <div className="font-light font-semibold text-xl pb-5">Taux de remplissage par horaire</div>
            <Chart type="line" data={chartData} options={chartOptions} pt={{
                root: {
                    style: {
                        height: '400px'
                    }
                }
            }}/>
        </div>
    )
}