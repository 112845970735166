import { DataTable, DataTableFilterMeta, DataTableRowClickEvent } from 'primereact/datatable';
import React, { useState } from 'react';
import { PassengerReservation } from '../../../service/type';
import { Column } from 'primereact/column';
import { ID_DOC_MAP } from '../../../utils';
import { Badge } from 'primereact/badge';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Sidebar } from 'primereact/sidebar';
import { useLazyGetBookingMessagesInfoQuery } from '../../../service/messagingApi';
import { ReservationDetailsSidebarContent } from './reservation/ReservationDetailsSidebarContent';
import { useGetTravelPassengersQuery, useLazyGetReservationQuery } from '../../../service/bookingApi';
import { ProgressSpinner } from 'primereact/progressspinner';
import { differenceInMilliseconds } from 'date-fns';

export interface TravelPassengersListProps {
    travelId?: number;
}

export const TravelPassengersList = ({ travelId }: TravelPassengersListProps) => {
    const [getMessagesInfo, { 
        data: messagesInfo, 
        isLoading: messagesInfoQIsLoading, 
        isFetching: messagesInfoQIsFetching 
    }] = useLazyGetBookingMessagesInfoQuery();

    const [getReservation, { 
        data: reservation, 
        isLoading: reservationQIsLoading, 
        isFetching: reservationQIsFetching 
    }] = useLazyGetReservationQuery();

    const { 
        data: passengersQData, 
        isLoading: passengersQIsLoading, 
        isFetching: passengersQIsFetching 
    } = useGetTravelPassengersQuery(
        { travelId: travelId ? travelId : -1 }, 
        { skip: travelId === undefined }
    );

    const [selectedPassenger, setSelectedPassenger] = useState<PassengerReservation>();
    const [reservationPanelVisible, setReservationPanelVisible] = useState(false);

    // Filters
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };
        // @ts-ignore
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => (
        <div className="flex justify-content-end mb-2">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                    placeholder="Search"
                    type="text"
                    className="p-inputtext-sm w-8rem sm:w-auto"
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    pt={{
                        root: {
                            style: {
                                borderRadius: "2rem",
                                paddingTop: ".5rem",
                                paddingBottom: ".5rem"
                            }
                        }
                    }}
                />
            </IconField>
        </div>
    );

    const loader = (
        <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
        </div>
    );

    const handleRowClick = (event: DataTableRowClickEvent) => {
        setReservationPanelVisible(true);
        getMessagesInfo({ bookingId: event.data.bookingId });
        getReservation({ bookingId: event.data.bookingId });
    };

    const renderIdDocType = (reservation: PassengerReservation) => (
        <span>{ID_DOC_MAP.get(reservation.reservationItem.passenger.identityDocument.documentType)}</span>
    );

    const renderCost = (reservation: PassengerReservation) => (
        <span>{reservation.reservationItem.cost.toLocaleString()}</span>
    );

    return (
        <div className="card flex-grow-1" style={{overflowX: 'auto'}}>
            <span className="font-light font-semibold text-xl pb-5">
                <div className="flex flex-grow-1">
                    <div className="p-overlay-badge">
                        Passagers
                        {passengersQData && <Badge severity="danger" value={passengersQData.length} />}
                    </div>
                    <div className="flex-grow-1">
                        {renderHeader()}
                    </div>
                </div>
            </span>

            <DataTable
                value={passengersQData ? 
                    [...passengersQData].sort((a, b) => 
                        differenceInMilliseconds(a.bookingDate, b.bookingDate)
                    ) : []
                }
                emptyMessage={<p className='text-center'>Aucune reservation pour ce voyage</p>}
                size="small"
                className="common-scroll"
                selectionMode="single"
                selection={selectedPassenger}
                metaKeySelection={true}
                filters={filters}
                globalFilterFields={[
                    'reservationItem.passenger.lastName',
                    'reservationItem.passenger.firstName'
                ]}
                stripedRows
                sortField='bookingDate'
                rows={15}
                paginator
                alwaysShowPaginator={false}
                onSelectionChange={(e) => setSelectedPassenger(e.value as PassengerReservation)}
                onRowClick={handleRowClick}
                loading={passengersQIsFetching || passengersQIsLoading}
                loadingIcon={loader}
                tableStyle={{ minWidth: '50rem' }}
                pt={{
                    wrapper: {
                        className: "common-scroll"
                    }
                }}
            >
                <Column field="reservationItem.passenger.lastName" header="Nom" sortable />
                <Column field="reservationItem.passenger.firstName" header="Prénom" sortable />
                <Column 
                    field="reservationItem.passenger.identityDocument.documentType" 
                    header="Type doc. ID"
                    body={renderIdDocType}
                />
                <Column field="reservationItem.passenger.identityDocument.documentID" header="Nº doc ID" />
                <Column field="reservationItem.bookingSeat.seatLabel" header="Siège" />
                <Column 
                    field="reservationItem.cost" 
                    body={renderCost}
                    header="Prix" 
                />
            </DataTable>

            {reservation && messagesInfo && (
                <Sidebar
                    visible={reservationPanelVisible}
                    showCloseIcon={false}
                    className="w-full md:w-20rem lg:w-30rem"
                    position="right"
                    onHide={() => setReservationPanelVisible(false)}
                    pt={{
                        content: {
                            className: "common-scroll"
                        }
                    }}
                >
                    <ReservationDetailsSidebarContent 
                        messagesInfo={messagesInfo} 
                        reservation={reservation}
                    />
                </Sidebar>
            )}
        </div>
    );
};
