import React, { useRef, useState } from 'react'
import { StationHomePageProps } from '../../propsType'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useDeleteStationMutation, useGetAllStationQuery } from '../../service/travelMgrApi'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Station } from '../../service/type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faTrafficLight } from '@fortawesome/free-solid-svg-icons'
import { Tag } from 'primereact/tag'
import { Card } from 'primereact/card'
import { StationForm } from './StationForm'
import { STATION_TYPE_STATION, STATION_TYPE_STOP } from '../../data'
import { Toast } from 'primereact/toast'
import { CityDisplayTemplate } from '../city/CityDisplayTemplate'



export const StationHomePage = ({} : StationHomePageProps) => {

    const [selectedStation, setSelectedStation] = useState<any>()
    const [visible, setVisible] = useState<boolean>(false)

    const { data, error, isLoading, status, isError, isFetching } = useGetAllStationQuery()
    const [deleteStationApi] = useDeleteStationMutation()

    const toast = useRef<Toast>(null)


    const noStationMessageComponent = () => {
        return <div className='flex flex-column align-items-center'>
          <div className='my-2'>
            Aucune gare et escale
          </div>
          <div className='my-2 flex gap-3'>
            <Button label="Enregistrer une gare - scale" size='small' icon="pi pi-map-marker" onClick={() => setVisible(true)}/>
          </div>
        </div>
      }

    const cityTemplate = (rowData: Station) => {
        return (
            <CityDisplayTemplate city={rowData.city}/>
        );
    };

    const stationTemplates = (rowData: Station) => {
        const isStop = rowData.stationType.code == STATION_TYPE_STOP
        return (
            <div>
              <Tag value={rowData.stationType.label} severity={isStop ? "warning" : "success" }  icon={<FontAwesomeIcon className='mr-1' icon={isStop ? faTrafficLight : faBuilding } />}/>
            </div>
        );
      };

    
    const stationCardTitle = () => {
        return <div className='flex justify-content-between flex-wrap'>
            <div>Gestion des Gares et escales</div>
            <div className='flex align-items-center justify-content-center'>
                <Button 
                    size='small' type="button" 
                    className='mt-1' outlined icon="pi pi-plus" 
                    label='Gare - Escale' onClick={() => setVisible(true)} 
                    pt={{
                        root: {
                          style: {
                            "paddingTop": ".5rem",
                            "paddingBottom": ".5rem"
                          }
                        }
                      }}/>
            </div>
        </div>
    }

    const actionTemplate = (station: Station) => {
        return <div className='flex gap-2'>
            <Button size='small' icon="pi pi-pencil" text  rounded aria-label="Filter" severity='contrast' />
            <Button size='small' icon="pi pi-trash" text  rounded aria-label="Filter" severity='danger' onClick={(e) => onStationDeletion(station.id)} />
        </div>
    }

    const onStationDeletion = async (stationId : number) => {
        try {
            const result = await deleteStationApi(stationId).unwrap()
        } catch (error) {
            throw error
        }
    }
    
    return (
        <Card title={stationCardTitle} className='shadow-none'>
            <div>

                {(isLoading || isFetching) &&
                    <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
                        <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                }

                {data &&
                    <DataTable value={data} size='small' dataKey="id" emptyMessage={noStationMessageComponent} selectionMode="single" selection={selectedStation} onSelectionChange={(e) => setSelectedStation(e.value)} tableStyle={{ minWidth: '50rem'}}>
                        <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="Nom" sortable />
                        <Column field="city.longName" header="Ville" body={cityTemplate} sortable/>
                        <Column field='stationType' header="Type" body={stationTemplates} sortable />
                        <Column header="Actions" style={{ flex: '0 0 4rem' }} body={actionTemplate} bodyStyle={{ width: '3rem' }}></Column>
                    </DataTable>
                }
                
                <StationForm visible={visible} setVisible={setVisible} />
                
            </div>
        </Card>
  )
}

export default StationHomePage