import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { Route } from "../../../service/type";
import { LightRouteDisplayTemplate } from "../../route/RouteDisplayTemplate";

export interface TravelHeaderProps {
    selectedRoute?: Route;
    dateRange: Nullable<(Date | null)[]>;
    routes?: Route[];
    isLoading: boolean;
    onRouteChange: (route: Route) => void;
    onDateRangeChange: (range: Nullable<(Date | null)[]>) => void;
}

export const TravelHeader = ({ 
    selectedRoute,
    dateRange,
    routes,
    isLoading,
    onRouteChange,
    onDateRangeChange
}: TravelHeaderProps) => {
    const routeItemTemplate = (route: Route) => (
        route ? <LightRouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={2} /> 
             : <div>Choisissez un itinéraire</div>
    );

    return (
        <div className="flex gap-3">
            <Dropdown 
                loading={isLoading}
                value={selectedRoute}
                id='route'
                itemTemplate={routeItemTemplate}
                valueTemplate={routeItemTemplate}
                scrollHeight="400px"
                onChange={(e) => onRouteChange(e.value)}
                options={routes}
                optionLabel="name"
                placeholder="Choisissez un itinéraire"
                className="w-full"
            />
            <Calendar
                value={dateRange}
                onChange={(e) => onDateRangeChange(e.value)}
                dateFormat="dd M"
                minDate={new Date()}
                locale='fr'
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                className='w-full'
            />
        </div>
    );
};
